import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Success } from './containers/Success';
import { AuthContextProvider } from './context/Auth.context';
import { OffersContextProvider } from './context/Offers.context';
import './main.css';
import { CheckoutPage } from './pages/CheckoutPage';
import { Home } from './pages/Home';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import Header from './components/Header';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ScrollToTop from './components/ScrollToTop';
import { PetsmartHome } from './pages/Petsmart/PetsmartHome';
import { PetsmartCheckout } from './pages/Petsmart/PetsmartCheckout';
import { DogdayHome } from './pages/dogday/DogdayHome';
import { DogdayCheckout } from './pages/dogday/DogdayCheckout';
import HubspotScriptLoader from './components/HubspotScriptLoader'; 
import { TryHome } from './pages/try/TryHome';
import { TryCheckout } from './pages/try/TryCheckout';
import { ChdiHome } from './pages/chdi/chdiHome';
import { ChdiCheckout } from './pages/chdi/chdiCheckout';
import { PetsvivoHome } from './pages/petsvivo/PetsvivoHome';
import { PetsvivoCheckout } from './pages/petsvivo/PetsvivoCheckout';
import { ParamountHome } from './pages/paramount/paramountHome';
import { ParamountCheckout } from './pages/paramount/paramountCheckout';
import { UtmHandler } from './utils/useUtmHandler';
import { HelmetProvider } from 'react-helmet-async';
import { HowlidayCheckout } from './pages/howliday/howlidayCheckout';
import { Paw30Home } from './pages/paw30/Paw30Home';
import { Paw30Checkout } from './pages/paw30/Paw30Checkout';
import { Pups30Home } from './pages/pups30/Pups30Home';
import { Pups30Checkout } from './pages/pups30/Pups30Checkout';
import { Promo256mHome } from './pages/promo256m/Promo256mHome';
import { Promo256mCheckout } from './pages/promo256m/Promo256mCheckout';
import { Testimonials } from './pages/Testimonials';

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1] || 'home';
    const isCheckout = location.pathname.includes('checkout');
    document.body.className = `page-${currentPath}${isCheckout ? ' atcheckout' : ''}`;
  }, [location.pathname]);

  return (
    <HelmetProvider>
    <AuthContextProvider>
      <OffersContextProvider>
        <UtmHandler>
          <div id="root" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <ScrollToTop />
            <main style={{ flex: 1 }}>
              <Routes>
              <Route index element={<Home />} />
              <Route path="landing" element={<Navigate to="/" replace />} />
              <Route path="landing/" element={<Navigate to="/" replace />} />
              <Route path="checkout" element={<CheckoutPage />} />
              <Route path="checkout/" element={<CheckoutPage />} />
              <Route path="petsmart" element={<PetsmartHome />} />
              <Route path="petsmart/" element={<PetsmartHome />} />
              <Route path="petsmart/checkout" element={<PetsmartCheckout />} />
              <Route path="petsmart/checkout/" element={<PetsmartCheckout />} />
              <Route path="dogday" element={<DogdayHome />} />
              <Route path="dogday/" element={<DogdayHome />} />
              <Route path="dogday/checkout" element={<DogdayCheckout />} />
              <Route path="dogday/checkout/" element={<DogdayCheckout />} />
              <Route path="try" element={<TryHome />} />
              <Route path="try/" element={<TryHome />} />
              <Route path="try/checkout" element={<TryCheckout />} />
              <Route path="try/checkout/" element={<TryCheckout />} />
              <Route path="chdi" element={<ChdiHome />} />
              <Route path="chdi/" element={<ChdiHome />} />
              <Route path="chdi/checkout" element={<ChdiCheckout />} />
              <Route path="chdi/checkout/" element={<ChdiCheckout />} />
              <Route path="petsvivo" element={<PetsvivoHome />} />
              <Route path="petsvivo/" element={<PetsvivoHome />} />
              <Route path="petsvivo/checkout" element={<PetsvivoCheckout />} />
              <Route path="petsvivo/checkout/" element={<PetsvivoCheckout />} />
              <Route path="paramount" element={<ParamountHome />} />
              <Route path="paramount/" element={<ParamountHome />} />
              <Route path="paramount/checkout" element={<ParamountCheckout />} />
              <Route path="paramount/checkout/" element={<ParamountCheckout />} />
              {/*<Route path="howliday/checkout" element={<HowlidayCheckout />} />
              <Route path="howliday/checkout/" element={<HowlidayCheckout />} />*/}
              <Route path="paw30" element={<Paw30Home />} />
              <Route path="paw30/" element={<Paw30Home />} />
              <Route path="paw30/checkout" element={<Paw30Checkout />} />
              <Route path="paw30/checkout/" element={<Paw30Checkout />} />
              <Route path="pups30" element={<Pups30Home />} />
              <Route path="pups30/" element={<Pups30Home />} />
              <Route path="pups30/checkout" element={<Pups30Checkout />} />
              <Route path="pups30/checkout/" element={<Pups30Checkout />} />
              <Route path="promo256m" element={<Promo256mHome />} />
              <Route path="promo256m/" element={<Promo256mHome />} />
              <Route path="promo256m/checkout" element={<Promo256mCheckout />} />
              <Route path="promo256m/checkout/" element={<Promo256mCheckout />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="privacy/" element={<Privacy />} />
              <Route path="success" element={<Success />} />
              <Route path="success/" element={<Success />} />
              <Route path="terms" element={<Terms />} />
              <Route path="terms/" element={<Terms />} />
              <Route path="testimonials" element={<Testimonials />} />
              <Route path="testimonials/" element={<Testimonials />} />
              <Route path="start" element={<Navigate to="/" replace />} />
              <Route path="*" element={<Home />} />
              </Routes>
            </main>
            <HubspotScriptLoader />
            <Footer />
          </div>
        </UtmHandler>
      </OffersContextProvider>
    </AuthContextProvider>
    </HelmetProvider>
  );
};

export default App;
